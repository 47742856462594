// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-concierge-services-js": () => import("./../../../src/pages/services/concierge-services.js" /* webpackChunkName: "component---src-pages-services-concierge-services-js" */),
  "component---src-pages-services-deep-cleaning-js": () => import("./../../../src/pages/services/deep-cleaning.js" /* webpackChunkName: "component---src-pages-services-deep-cleaning-js" */),
  "component---src-pages-services-domestic-cleaning-js": () => import("./../../../src/pages/services/domestic-cleaning.js" /* webpackChunkName: "component---src-pages-services-domestic-cleaning-js" */),
  "component---src-pages-services-gardening-js": () => import("./../../../src/pages/services/gardening.js" /* webpackChunkName: "component---src-pages-services-gardening-js" */),
  "component---src-pages-services-house-keeping-js": () => import("./../../../src/pages/services/house-keeping.js" /* webpackChunkName: "component---src-pages-services-house-keeping-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-office-cleaning-js": () => import("./../../../src/pages/services/office-cleaning.js" /* webpackChunkName: "component---src-pages-services-office-cleaning-js" */),
  "component---src-pages-tcs-js": () => import("./../../../src/pages/tcs.js" /* webpackChunkName: "component---src-pages-tcs-js" */)
}

